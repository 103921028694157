import { Dispatch } from "redux";
import axios from "axios";
import { ItezAction, ItezParamsActionTypes } from '../types/Itez';
import { ItezData } from '../../model/itez';
import { config } from '../../utils/config';
import { validateItezInitData } from '../../utils/validateItezInitData';

export const ItezActionCreator = {
    setData: (data: ItezData) => (dispatch: Dispatch<ItezAction>) => {
        dispatch({type: ItezParamsActionTypes.SET_ITEZ_DATA, payload: validateItezInitData(data)})
    },
    fetchParams: (data: ItezData) => async (dispatch: Dispatch<ItezAction>) => {
        try {
            dispatch({type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS})
            const response = await axios.post(
                '/createItezInit',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    baseURL: config.url.ITEZ_API_URL
                }
            )
            dispatch({type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS_SUCCESS, payload: response.data})
        } catch (e) {
            dispatch({
                type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS_ERROR,
                payload: 'Error'
            })
        }
    }
}

