import { combineReducers } from "redux";
import { streamPaymentsReducer } from "./streamPaymentsReducer";
import { checkStatusPaymentReducer } from "./checkStatusPaymentReducer";
import { itezReducer } from './itezReducer';
import { initParamsReducer } from './initParamsReducer';


export const rootReducer = combineReducers({
    streamPayments: streamPaymentsReducer,
    checkStatusPayment: checkStatusPaymentReducer,
    itez: itezReducer,
    initParams: initParamsReducer
})

export type RootState = ReturnType<typeof rootReducer>
