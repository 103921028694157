import { Dispatch } from "redux";
import { QueryParams } from '../../model/queryParams';
import { InitParamsAction, InitParamsActionTypes } from '../types/initParams';

export const InitActionCreator = {
    setInitParams: (data: QueryParams) => (dispatch: Dispatch<InitParamsAction>) => {
        dispatch({type: InitParamsActionTypes.SET_INIT_PARAMS, payload: data})
    }
}

