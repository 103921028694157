/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Box, Link, Typography} from "@mui/material";
import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Loader from "../components/Loader";

const StatusPayment = () => {
    const {response, loading} = useTypeSelector(state => state.checkStatusPayment);
    const [url, setUrl] = useState("");
    const id = sessionStorage.getItem("ID");
    const {fetchCheckStatusPayment} = useActions();
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        if (id) {
            let checkID = id.toString();
            fetchCheckStatusPayment(checkID);
        }
    }, []);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        }
        if (timer === 0) {
            document.location.href = url;
        }
    }, [timer]);
    useEffect(() => {
        setUrl(response.production ? "https://pro.frtn.trade/" : "https://dev.frtn.trade/")
    }, [response.production]);

    if (loading) {
        return <Loader/>
    }

    return (
        <Box
            component="div"
            textAlign="center"
        >

            {
                response.success ?
                    <React.Fragment>
                        <Typography
                            variant={"h5"}
                            color={"#299400"}
                        >
                            Payment completed
                        </Typography>
                        <CheckCircleIcon fontSize={"large"} color={"success"}/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Typography
                            variant={"h5"}
                            color={"#d32f2f"}
                        >
                            Payment canceled
                        </Typography>
                        <CancelIcon fontSize={"large"} color={"error"}/>
                    </React.Fragment>
            }

            <Typography
                marginBottom={2}
            >
                Please go to <Link
                sx={{
                    color: "#50AF9A"
                }}
                href={url}
            >Fortune.</Link>
            </Typography>
            <Typography
                align="left"
                color="#A1A1A1"
                marginBottom={2}
            >
                automatically redirect after {timer} seconds
            </Typography>
        </Box>
    );
};

export default StatusPayment;