import {
    CheckStatusPaymentAction,
    CheckStatusPaymentActionTypes,
    ICheckStatus,
} from "../types/checkStatusPayment";

const initialState: ICheckStatus = {
    checkoutId: "",
    response: {
        success: false,
        partnerId: 0,
        status: "",
        statusDescription: "",
        production: false,
        ndc: ""
    },
    loading: false,
    error: null
}

export const checkStatusPaymentReducer = (state = initialState, action: CheckStatusPaymentAction): ICheckStatus => {
    switch (action.type) {
        case CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT:
            return {...state, loading: true, error: null, checkoutId: ""}
        case CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_SUCCESS:
            return {...state,loading: false, error: null, response: action.payload}
        case CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_ERROR:
            return {...state,loading: false, error: action.payload}
        default:
            return state
    }
}
