import {Dispatch} from "redux";
import axios from "axios";
import {CheckStatusPaymentAction, CheckStatusPaymentActionTypes} from "../types/checkStatusPayment";

export const CheckStatusActionsCreator = {
    fetchCheckStatusPayment: (id: string) => async (dispatch: Dispatch<CheckStatusPaymentAction>) => {
        try {
            dispatch({type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT})
            const response = await axios.get(`https://pay-dev.frtn.trade/api/streamPayments/debitStatusOur//${id}`)
            dispatch({type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_SUCCESS, payload: response.data})
        } catch (e) {
            dispatch({
                type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_ERROR,
                payload: 'Error'
            })
        }
    }
}
