import { StreamPaymentsActionCreator } from "./streamPayments";
import { CheckStatusActionsCreator } from "./checkStatusPayment";
import { ItezActionCreator } from './Itez';
import { InitActionCreator } from './initParams';

const exportedObject = {
    ...StreamPaymentsActionCreator,
    ...CheckStatusActionsCreator,
    ...ItezActionCreator,
    ...InitActionCreator
}

export default exportedObject