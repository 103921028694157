/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from "@mui/material";
import { useTypeSelector } from '../hooks/useTypeSelector';
import { config } from '../utils/config';

interface ParamsRedirect {
    url: string;
    namePartner: string;
}

const Error = () => {
    const {queryParams} = useTypeSelector(state => state.initParams)
    const [paramsRedirect, setParamsRedirect] = useState<ParamsRedirect>({
            url: config.url.EXPI_RIDERECT,
            namePartner: "ExPi"
        }
    )
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        switch (queryParams.partner) {
            case 'frtn':
                return setParamsRedirect({
                    ...paramsRedirect,
                    url: config.url.FRTN_RIDERECT,
                    namePartner: "Fortune"
                })
            case 'evercash':
                return setParamsRedirect({
                    ...paramsRedirect,
                    url: config.url.EVERCASH_RIDERECT,
                    namePartner: "Evercash"
                })
            default:
                return setParamsRedirect({
                    ...paramsRedirect,
                    url: config.url.EXPI_RIDERECT,
                    namePartner: "ExPi"
                })
        }
    }, [queryParams.partner])

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1)
            }, 1000)
        }
        if (timer === 0) {
            document.location.href = paramsRedirect.url;
        }
    }, [timer])

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography
                marginBottom={ 2 }
            >
                Empty order. Please go to <Link
                sx={ {
                    color: "#50AF9A"
                } }
                href={paramsRedirect.url}
            >{paramsRedirect.namePartner}.</Link>
            </Typography>
            <Typography
                align="left"
                color="#A1A1A1"
                marginBottom={ 2 }
            >
                automatically redirect after { timer } seconds
            </Typography>
        </Box>
    );
};

export default Error;