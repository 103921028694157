import React, {FC} from 'react';
import {Box, CircularProgress} from "@mui/material";

const Loader: FC = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress
                sx={{
                    color: "#50AF9A",
                }}
            />
        </Box>
    );
};

export default Loader;