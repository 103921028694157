import { InitParams, InitParamsAction, InitParamsActionTypes } from '../types/initParams';


const initialState: InitParams = {
    queryParams: {
        partner: 'frtn',
        dev: '',
        accountId: '',
        email: '',
        acquiring: '',
        to_amount: 0,
        from_amount: 0
    },
    save: false
}

export const initParamsReducer = (state = initialState, action: InitParamsAction): InitParams => {
    switch (action.type) {
        case InitParamsActionTypes.SET_INIT_PARAMS:
            return {...state, save: true,queryParams: action.payload}
        default:
            return state
    }
}
