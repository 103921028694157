/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTypeSelector } from '../hooks/useTypeSelector';
import Loader from './Loader';
import { useHistory } from 'react-router-dom';
import { acquiringList } from '../utils/const';
import { useActions } from '../hooks/useActions';

export function Init () {
    const {save, queryParams} = useTypeSelector(state => state.initParams);
    const {setData} = useActions();
    const history = useHistory();

    useEffect(()=>{
        setData({
            email: String(queryParams.email),
            accountId: String(queryParams.accountId),
            to_amount: Number(queryParams.to_amount),
            from_amount: Number(queryParams.from_amount)
        })

        switch (queryParams.acquiring) {
            case acquiringList.ITEZ:
                return history.push('/itez');
            case acquiringList.STREAM_PAYMENTS:
                return history.push('/stream-payments');
            default:
                return history.push('/error')
        }
    }, [])

    if (!save) {
        return <Loader/>
    }
    return (
        <div>Init</div>
    )
}
