import { IItezParams, ItezAction, ItezParamsActionTypes } from '../types/Itez';


const initialState: IItezParams = {
    data: {
        email: "",
        accountId: "",
        to_amount: 0,
        from_amount: 0
    },
    params: {
        partner_token : "",
        timestamp : 0,
        target_element : "",
        from_currency : "",
        to_currency : "",
        to_account : "",
        user_login : "",
        partner_operation_id : "",
        signature : ""
    },
    loading: false,
    error: null
}

export const itezReducer = (state = initialState, action: ItezAction): IItezParams => {
    switch (action.type) {
        case ItezParamsActionTypes.SET_ITEZ_DATA:
            return {...state, data: action.payload}
        case ItezParamsActionTypes.FETCH_ITEZ_PARAMS:
            return {...state, loading: true, error: null}
        case ItezParamsActionTypes.FETCH_ITEZ_PARAMS_SUCCESS:
            return {...state,loading: false, error: null, params: action.payload}
        case ItezParamsActionTypes.FETCH_ITEZ_PARAMS_ERROR:
            return {...state,loading: false, error: action.payload, params: initialState.params}
        default:
            return state
    }
}
