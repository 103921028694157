import { QueryParams } from '../../model/queryParams';

export interface InitParams {
    queryParams: QueryParams;
    save: boolean;

}

export enum InitParamsActionTypes {
    SET_INIT_PARAMS = 'SET_INIT_PARAMS'
}

interface SetInitParamsAction {
    type: InitParamsActionTypes.SET_INIT_PARAMS;
    payload: QueryParams;
}



export type InitParamsAction = SetInitParamsAction
