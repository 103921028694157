const DEV = {
    url: {
        API_URL: 'https://pay.expi.trade',
        ITEZ_API_URL: 'https://pay.evercash.eu/api/itez/',
        FRTN_RIDERECT: 'https://dev.frtn.trade/login/',
        EVERCASH_RIDERECT: 'https://dev.evercash.eu/login/',
        EXPI_RIDERECT: 'https://pro.expi.trade/',
    }
}
const PROD = {
    url: {
        API_URL: 'https://pay.expi.trade',
        ITEZ_API_URL: 'https://pay.evercash.eu/api/itez/',
        FRTN_RIDERECT: 'https://pro.frtn.trade/login/',
        EVERCASH_RIDERECT: 'https://app.evercash.eu/login/',
        EXPI_RIDERECT: 'https://pro.expi.trade/',
    }
}
export const config = process.env.REACT_APP_STAGE === 'production' ? PROD : DEV;
