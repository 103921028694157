/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useActions } from '../hooks/useActions';
import { useTypeSelector } from '../hooks/useTypeSelector';
import Loader from './Loader';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';

const Itez = () => {
    const history = useHistory();
    const {loading, error, params, data} = useTypeSelector(state => state.itez);
    const {fetchParams} = useActions();
    const _ref = React.createRef<any>();

    useEffect(() => {
        fetchParams(data);
    }, [data])

    useEffect(() => {
        const scriptFrame = document.createElement("script");
        scriptFrame.async = true;
        scriptFrame.innerHTML = `ItezWidget.run(${JSON.stringify(params)});`
        const script = document.createElement("script");
        script.src = "https://pay.itez.com/static/main/share/merchant.js";
        script.async = true;
        _ref.current.appendChild(script)
        _ref.current.appendChild(scriptFrame)
    }, [params])
    if (loading || _ref.current !== null) {
        return <Loader/>
    }
    if (error) {
        history.push('/error')
    }
    return (
        <Box
            ref={_ref}
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <div
                style={{
                    width: "100%"
                }}
                id="widget-container"/>
        </Box>
    );
};

export default Itez;