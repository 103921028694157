/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {useTypeSelector} from "../hooks/useTypeSelector";
import {Box, Link, Typography} from "@mui/material";

import visaLogo from "../images/visa.svg";
import masterCardLogo from "../images/mastercard.svg";
import Loader from "./Loader";
import useWidth from "../hooks/useWidth";
import Error from "./Error";


const StreamPayments: FC = () => {
    const devUrl = 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=';
    const prodUrl = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId=';
    const search = window.location.search.substring(1);
    const [params, setParams] = useState({
        id: "",
        amount: "",
        dev: ""
    });
    const {loading, error} = useTypeSelector(state => state.streamPayments)
    const script = document.createElement<any>('script');
    const _ref = React.createRef<HTMLDivElement>();
    let width = useWidth();
    useEffect(() => {
        if (search.includes("id") && search.includes("amount") && search.includes("dev")) {
            setParams(
                JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                    return key === "" ? value : decodeURIComponent(value)
                }))
        }
    }, [])

    useEffect(()=> {
        if(params.id){
            sessionStorage.setItem("ID", params.id)
        }
        if(params.dev){
            sessionStorage.setItem("dev", params.dev)
        } else {
            sessionStorage.removeItem("dev")
        }
    },[params.id])

    useEffect(() => {
        if (params.id && params.amount && _ref.current) {
            script.src = params.dev === "true" ? devUrl + params.id : prodUrl + params.id
            script.async = true;
            script.innerHTML = /* JSON-ENCODED SETTINGS STRING FROM EMBED CODE */
                _ref.current.appendChild<HTMLScriptElement>(script);
        }
    }, [_ref, script])

    if (loading) {
        return <Loader/>
    }

    if (error || !(params.id && params.amount)) {
        return (
                <Error />
        );
    }

    return (
        <Box
            component="div"
            textAlign="left"
        >
            <Typography
                align="center"
                variant="h4"
                marginBottom={2}
            >
                Amount: {params.amount} EUR
            </Typography>
            <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="290px"
            >
                <form
                    action="https://pay.expi.trade/status-page"
                    className="paymentWidgets"
                    data-brands="VISA MASTER"
                />

                <div
                    className="tradingview-widget-container"
                    ref={_ref}
                />
            </Box>

            <Typography
                align="left"
                color="#A1A1A1"
                marginBottom={2}
                paddingLeft={width > 480 ? 4 : 0}
            >
                Descriptor statement: This transaction will appear on your cardholder statement as "Expi.trade +372 601
                9100".
            </Typography>

            <Box
                display="flex"
                justifyContent="center"
                marginBottom={2}
            >
                <img
                    src={visaLogo}
                    alt="Visa"
                    style={{
                        marginRight: 12
                    }}
                />
                <img src={masterCardLogo} alt="Master card"/>
            </Box>
            <Typography
                paddingLeft={width > 480 ? 4 : 0}
                variant="caption"
                display="block"
                gutterBottom
                align="left"
                color="#A1A1A1"
            >
                Bertes Invest OU (reg. No 12449024) is registered in Estonia with its registration address at Harju
                maakond, Tallinn, Kesklinna linnaosa, Veskiposti tn 2, 10138, Estonia. Bertes Invest OU operates under
                Financial services, Providing a virtual currency services license (license number: FVT000291), issued by
                Estonian Financial Intelligence Unit. Contact e-mail: <Link
                sx={{
                    color: "#50AF9A"
                }}
                href="mailto:support@expi.trade"
            >support@expi.trade.</Link>
            </Typography>
        </Box>
    );
};

export default StreamPayments;
