import { ItezData, ItezResponse } from '../../model/itez';

export interface IItezParams {
    data: ItezData;
    params: ItezResponse;
    loading: boolean;
    error: null | string;
}

export enum ItezParamsActionTypes {
    SET_ITEZ_DATA = 'SET_ITEZ_DATA',
    FETCH_ITEZ_PARAMS = 'FETCH_ITEZ_PARAMS',
    FETCH_ITEZ_PARAMS_SUCCESS = 'FETCH_ITEZ_PARAMS_ID_SUCCESS',
    FETCH_ITEZ_PARAMS_ERROR = 'FETCH_ITEZ_PARAMS_ID_ERROR',
}

interface SetItezDataAction {
    type: ItezParamsActionTypes.SET_ITEZ_DATA;
    payload: ItezData;
}
interface FetchItezParamsAction {
    type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS;
}

interface FetchItezParamsSuccessAction {
    type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS_SUCCESS;
    payload: ItezResponse;
}

interface FetchItezParamsErrorAction {
    type: ItezParamsActionTypes.FETCH_ITEZ_PARAMS_ERROR;
    payload: string;
}

export type ItezAction = SetItezDataAction | FetchItezParamsAction | FetchItezParamsSuccessAction | FetchItezParamsErrorAction
