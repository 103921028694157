import { ItezData } from '../model/itez';

export function validateItezInitData(obj: ItezData): ItezData{
    for (let objKey in obj) {
        if(objKey === "to_amount" || objKey === "from_amount") {
            Number(obj[objKey]) <= 0 && delete obj[objKey]
        }
    }

    return obj;
}