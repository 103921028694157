/* eslint-disable react-hooks/exhaustive-deps */

import React, { FC, useEffect } from 'react';
import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Redirect, Route, Switch, } from "react-router-dom";
import "./style.css";
import { RouteNames, routes } from './routes';
import { parseQueryParams } from './utils/parseQueryParams';
import { useActions } from './hooks/useActions';
const App :FC = () => {
    const theme = createTheme();
    const search = window.location.search;
    const {setInitParams} = useActions();
    useEffect(()=> {
            setInitParams(parseQueryParams(search));
    },[search])
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Container
                    component="main"
                    maxWidth={'lg'}
                    sx={{
                        marginTop: 4,
                        width: '100%',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CssBaseline/>
                    <Switch>
                        {routes.map(route =>
                            <Route
                                key={route.path}
                                exact={route.exact}
                                path={route.path}
                                component={route.component}
                            />
                        )}
                        <Redirect to={RouteNames.INIT}/>
                    </Switch>
                </Container>
                {/*<div*/}
                {/*    style={{*/}
                {/*        display: "flex",*/}
                {/*        justifyContent: "cener",*/}
                {/*        alignItems: 'center',*/}
                {/*        overflow: 'hidden',*/}
                {/*        height: '100vh',*/}
                {/*        padding: 0,*/}
                {/*        margin: 0,*/}
                {/*        backgroundColor: '#000',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        style={{*/}
                {/*            width: '100%',*/}
                {/*            height: 'auto'*/}
                {/*        }}*/}
                {/*        src={src}*/}
                {/*        alt="Coming soon"*/}
                {/*    />*/}
                {/*</div>*/}
            </BrowserRouter>

        </ThemeProvider>
    );
};

export default App;