import {IStreamPaymentID, StreamPaymentIDActionTypes, StreamPaymentsAction} from "../types/streamPayments";

const initialState: IStreamPaymentID = {
    checkoutId: "",
    loading: false,
    error: null
}

export const streamPaymentsReducer = (state = initialState, action: StreamPaymentsAction): IStreamPaymentID => {
    switch (action.type) {
        case StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID:
            return {...state, loading: true, error: null, checkoutId: ""}
        case StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID_SUCCESS:
            return {...state,loading: false, error: null, checkoutId: action.payload}
        case StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID_ERROR:
            return {...state,loading: false, error: action.payload, checkoutId: ""}
        default:
            return state
    }
}
