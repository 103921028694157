import {Route} from "../model/route";
import StreamPayments from "../components/StreamPayments";
import Error from "../components/Error";
import StatusPayment from "../pages/StatusPayment";
import Itez from '../components/Itez';
import { Init } from '../components/Init';

export enum RouteNames {
    STREAM_PAYMENTS = "/stream-payments",
    ITEZ = "/itez",
    STATUS_PAGE = "/status-page",
    ERROR = "/error",
    INIT = "/INIT",
}

export const routes:Route[] = [
    {
        exact: true,
        path: RouteNames.INIT,
        component: Init,
    },
    {
        exact: true,
        path: RouteNames.STREAM_PAYMENTS,
        component: StreamPayments,
    },
    {
        exact: true,
        path: RouteNames.ITEZ,
        component: Itez,
    },
    {
        exact: true,
        path: RouteNames.ERROR,
        component: Error
    },
    {
        exact: true,
        path: RouteNames.STATUS_PAGE,
        component: StatusPayment
    }
]