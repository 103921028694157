import { QueryParams } from '../model/queryParams';

export function parseQueryParams(str: string): QueryParams {
    let partner = '';
    if (sessionStorage.getItem("partner")) {
        partner = String(sessionStorage.getItem("partner"));
    }

    const params: QueryParams = {
        partner:  new URLSearchParams(str).get('partner') ? new URLSearchParams(str).get('partner') : partner,
        dev: new URLSearchParams(str).get('dev') ? new URLSearchParams(str).get('dev') : 'prod',
        acquiring: new URLSearchParams(str).get('acquiring'),
        email: new URLSearchParams(str).get('email'),
        accountId: new URLSearchParams(str).get('accountId'),
        to_amount: Number(new URLSearchParams(str).get('to_amount')) >= 0 ? Number(new URLSearchParams(str).get('to_amount')) : 0,
        from_amount: Number(new URLSearchParams(str).get('from_amount')) >= 0 ? Number(new URLSearchParams(str).get('from_amount')) : 0,
    }
    sessionStorage.setItem("partner", String(params.partner));
    return params
}