export interface IResponseCheckStatus {
    success: boolean,
    partnerId: number,
    status: string,
    production: boolean,
    statusDescription: string,
    ndc: string
}

export interface ICheckStatus {
    checkoutId: string;
    response: IResponseCheckStatus;
    loading: boolean;
    error: null | string;
}



export enum CheckStatusPaymentActionTypes {
    FETCH_CHECK_STATUS_PAYMENT = 'FETCH_CHECK_STATUS_PAYMENT',
    FETCH_CHECK_STATUS_PAYMENT_SUCCESS = 'FETCH_CHECK_STATUS_PAYMENT_SUCCESS',
    FETCH_CHECK_STATUS_PAYMENT_ERROR = 'FETCH_CHECK_STATUS_PAYMENT_ERROR',
}

interface FetchCheckStatusPaymentAction {
    type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT;
}

interface FetchCheckStatusPaymentSuccessAction {
    type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_SUCCESS;
    payload: IResponseCheckStatus;
}

interface FetchCheckStatusPaymentErrorAction {
    type: CheckStatusPaymentActionTypes.FETCH_CHECK_STATUS_PAYMENT_ERROR;
    payload: string;
}

export type CheckStatusPaymentAction = FetchCheckStatusPaymentAction | FetchCheckStatusPaymentSuccessAction | FetchCheckStatusPaymentErrorAction
